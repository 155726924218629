import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import _range from 'lodash/range';
import { MONTH_ORDINAL_DAY } from 'lib/dateFormats';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_COMMENTS_PATH, FACILITATOR_GROUP_JOURNALS_PATH, FACILITATOR_GROUP_SHOW_PATH } from 'lib/routerPaths';
import { fetchSubGroupData, notificationTotalsForSubGroup, selectMaxDBs, selectMaxJournals } from 'store/facilitatorDashboard/selectors';
import Table from 'react-bootstrap/Table';
import Badge from 'components/shared/Badge/Badge';
import ExtendedParticipantsList from 'components/facilitator/ExtendedParticipantsList';
import FlaggedJournalList from 'components/facilitator/FlaggedJournalList';
import InsufficientParticipationList from 'components/facilitator/InsufficientParticipationList';
import NotificationBubble from 'components/facilitator/NotificationBubble';

function facilitationPointClass(journalOrDb, facilitatedTaskIds, hasFacilitationPointAssignment) {
  if (journalOrDb.journalTaskId) {
    if (hasFacilitationPointAssignment && facilitatedTaskIds.indexOf(journalOrDb.journalTaskId) < 0) {
      return 'active transparent';
    }
    return 'active';
  }
  if (hasFacilitationPointAssignment && facilitatedTaskIds.indexOf(journalOrDb.discussionBoardId) < 0) {
    return 'transparent';
  }
  return '';
}

function podTitle(beginSessionAt, closeSessionAt, extendedUntil, hasExtensionsInPod, subGroupName, facilitatorCount, groupName) {
  const groupBeginDate = format(parseISO(beginSessionAt), MONTH_ORDINAL_DAY);
  const groupCloseDate = format(parseISO(closeSessionAt), MONTH_ORDINAL_DAY);
  const groupExtendedDate = extendedUntil && format(parseISO(extendedUntil), MONTH_ORDINAL_DAY);
  const hasMultipleFacilitators = facilitatorCount > 1;

  let title;
  if (hasMultipleFacilitators) {
    title = `${groupName} - ${groupBeginDate} to ${groupCloseDate} - ${subGroupName}`;
  } else {
    title = `${groupName} - ${groupBeginDate} to ${groupCloseDate}`;
  }
  if (extendedUntil && hasExtensionsInPod) {
    title = `${title} - Extended until ${groupExtendedDate}`;
  }
  return title;
}

function SubGroup({ subGroupId }) {
  const subGroupData = useSelector((state) => fetchSubGroupData(state, subGroupId));
  const notificationTotals = useSelector((state) => notificationTotalsForSubGroup(state, subGroupId));
  const maxJournals = useSelector((state) => selectMaxJournals(state));
  const maxDBs = useSelector((state) => selectMaxDBs(state));

  const {
    groupBeginSessionAt,
    groupCloseSessionAt,
    groupExtendedUntil,
    groupId,
    groupName,
    journalNotifications,
    discussionBoardNotifications,
    subGroupName,
    facilitatorCount,
    facilitatedTaskIds,
    hasFacilitationPointAssignment,
    flaggedJournals,
    extendedParticipants,
    insufficientParticipations,
  } = subGroupData;
  const panelBodyStyles = _isEmpty(flaggedJournals) && _isEmpty(extendedParticipants) && _isEmpty(insufficientParticipations) ? { padding: 0 } : { padding: 15 };
  const orderedDBNotifications = _sortBy(Object.values(discussionBoardNotifications || {}), 'position');
  const orderedJournalNotifications = _sortBy(Object.values(journalNotifications || {}), 'position');
  const journalPadding = orderedJournalNotifications.length < maxJournals ? (maxJournals - orderedJournalNotifications.length) : 0;
  const dbPadding = orderedDBNotifications.length < maxDBs ? (maxDBs - orderedDBNotifications.length) : 0;

  return (
    <div className="card card-border mt-4">
      <div className="card-header d-flex align-items-center">
        <div className="text-truncate" style={{ width: '90%' }}>
          <Link to={buildRoutePath(FACILITATOR_GROUP_SHOW_PATH, { id: groupId })} className="btn-plain">
            { podTitle(groupBeginSessionAt, groupCloseSessionAt, groupExtendedUntil, !_isEmpty(extendedParticipants), subGroupName, facilitatorCount, groupName, groupId) }
          </Link>
          <small className="text-secondary">
            {` (id: ${groupId})`}
          </small>
        </div>
        <Badge title={notificationTotals} variant="green" pill className="ms-auto" />
      </div>
      <Table bordered className="pod-stats">
        <thead>
          <tr>
            {
              orderedJournalNotifications.map((j, index) => (
                <th
                  className="active"
                  key={j.journalTaskId}
                  title={j.name}
                  style={{ cursor: 'help' }}
                >
                  J
                  {index + 1}
                </th>
              ))
            }
            { _range(journalPadding).map((_n) => <th className="active" key={_n} aria-label="empty column" />) }
            {
              orderedDBNotifications.map((db, index) => (
                <th
                  key={db.discussionBoardId}
                  title={db.discussionBoardName}
                  style={{ cursor: 'help' }}
                >
                  DB
                  {index + 1}
                </th>
              ))
            }
            { _range(dbPadding).map((_n) => <th key={_n} aria-label="empty column" />) }
          </tr>
        </thead>
        <tbody>
          <tr>
            {
              _map(orderedJournalNotifications, (j, key) => {
                if (j.silenceJournalNotification) {
                  return (
                    <td className="active" key={key}>
                      Notifications
                      <br />
                      Silenced
                    </td>
                  );
                }
                const journalUrl = buildRoutePath(FACILITATOR_GROUP_JOURNALS_PATH, { id: groupId });
                return (
                  <td className={facilitationPointClass(j, facilitatedTaskIds, hasFacilitationPointAssignment)} key={key}>
                    <div title="Primary Journal Comments">
                      <NotificationBubble priority="high" to={journalUrl} count={j.primary.highPriorityTotal} />
                      <NotificationBubble priority="medium" to={journalUrl} count={j.primary.mediumPriorityTotal} />
                      <NotificationBubble priority="low" to={journalUrl} count={j.primary.lowPriorityTotal} />
                    </div>
                    <div title="Secondary Journal Comments" style={{ marginTop: 5 }} className="darken">
                      <NotificationBubble priority="high" to={journalUrl} count={j.replies.highPriorityTotal} />
                      <NotificationBubble priority="medium" to={journalUrl} count={j.replies.mediumPriorityTotal} />
                      <NotificationBubble priority="low" to={journalUrl} count={j.replies.lowPriorityTotal} />
                    </div>
                  </td>
                );
              })
            }
            { _range(journalPadding).map((_n) => <td className="active" key={_n} />) }
            {
              _map(orderedDBNotifications, (db, key) => {
                const dbUrl = buildRoutePath(FACILITATOR_COMMENTS_PATH, { id: groupId, subGroupId, discussionTaskId: db.discussionBoardId });
                return (
                  <td className={facilitationPointClass(db, facilitatedTaskIds, hasFacilitationPointAssignment)} key={key}>
                    <div>
                      <NotificationBubble
                        priority="high"
                        to={dbUrl}
                        count={db.highPriorityTotal}
                      />
                      <NotificationBubble
                        priority="medium"
                        to={dbUrl}
                        count={db.mediumPriorityTotal}
                      />
                      <NotificationBubble
                        priority="low"
                        to={dbUrl}
                        count={db.lowPriorityTotal}
                      />
                    </div>
                  </td>
                );
              })
            }
            { _range(dbPadding).map((_n) => <td key={_n} />) }
          </tr>
        </tbody>
      </Table>
      <div className="card-body" style={panelBodyStyles}>
        <div className="row">
          <FlaggedJournalList journals={flaggedJournals} />
          <ExtendedParticipantsList participants={extendedParticipants} groupId={groupId} />
          <InsufficientParticipationList ips={insufficientParticipations} groupId={groupId} />
        </div>
      </div>
    </div>
  );
}

SubGroup.propTypes = {
  subGroupId: PropTypes.number.isRequired,
};

export default SubGroup;
