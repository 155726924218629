import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LOAD_FACILITATOR_DISCUSSION_BOARDS, LOAD_INITIAL_STATE } from 'store/facilitatorDashboard/actions';
import { sortedDBNotifications } from 'store/facilitatorDashboard/selectors';
import Table from 'react-bootstrap/Table';
import DiscussionBoardRow from './DiscussionBoardRow';

function DiscussionBoardsTable() {
  const currentUser = useSelector((state) => state.currentUser);
  const { loadingSubGroups } = useSelector((state) => state.facilitatorDashboard);
  const discussionBoardNotifications = useSelector((state) => sortedDBNotifications(state));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!discussionBoardNotifications && currentUser?.id && !loadingSubGroups) {
      dispatch(LOAD_INITIAL_STATE.request());
      dispatch(LOAD_FACILITATOR_DISCUSSION_BOARDS.request({ id: currentUser?.id }));
    }
  }, [currentUser?.id, dispatch, discussionBoardNotifications, loadingSubGroups]);

  return (
    <Table bordered hover className="bg-white mb-4 mt-4 sn-table">
      <thead>
        <tr>
          <th>{}</th>
          <th className="text-start">Task</th>
          <th className="text-start">Group</th>
          <th className="text-start">Pod</th>
          <th className="text-start">Starting</th>
          <th className="text-start">Closing</th>
          <th className="text-start">Unread posts</th>
          <th>{}</th>
        </tr>
      </thead>
      <tbody>
        {discussionBoardNotifications?.map((db) => (
          <DiscussionBoardRow key={db.key} discussionBoard={db} />
        ))}
      </tbody>
    </Table>
  );
}

export default DiscussionBoardsTable;
